<template>
    <div class="profit-curve">
        <el-card>
            <div slot="header" class="clearfix">
                <span>盈亏曲线</span>
                <date-range style="float: right" v-on:change="getData"></date-range>
            </div>
            <h-chart ref="chart" id="chart" :options="options"></h-chart>
            <div class="blackboard">
                <p class="star-level"><span>指标星级：</span>
                    <el-rate v-model="starLevel" disabled></el-rate>
                </p>
                <p>指标释义：核算账户在统计周期内的累计净利润和手续费。</p>
                <p>如何使用：点击盈亏曲线上任意节点即可开启笔记功能，便于记录当日的操作心得，助你在不断总结中提升操作水平，建议每天通过盈亏曲线标记自我投资路上的点点滴滴。</p>
            </div>
        </el-card>
    </div>
</template>

<script>
  import HChart from '@/components/HChart.vue'
  import DateRange from '@/components/DateRange.vue'
  import {Loading} from 'element-ui';
  import {apiAnalysisData} from "../../api/analysis";

  export default {
    name: "ProfitCurve",
    data () {
      return {
        starLevel: 5,
        options: {
          credits: {
            enabled: false
          },
          title: false,
          chart: {
            zoomType: 'x',
            resetZoomButton: {
              // relativeTo: 'chart',
              position: {
                // align: 'right', // by default
                // verticalAlign: 'top', // by default
                x: 0,
                y: -35
              }
            },
            spacingTop: 35,
            backgroundColor: null,
            plotBackgroundColor: null
            // backgroundColor: '#ededed',
          },
          colors: ['#EB3E41', '#229AFC'],
          xAxis: {
            // tickInterval: 20,
            categories: []
          },
          yAxis: {
            title: false,
          },
          series: [],
        }
      }
    },
    components: {
      HChart,
      DateRange
    },
    methods: {
      async getData () {
        if (this.$store.state.curAccountId.length === 0) {
          return false;
        }
        let loadingInstance = Loading.service({target: '.h-chart'});
        const params = {
          a_id: this.$store.state.curAccountId.join(','),
          type: this.$store.state.analysisType,
          b_date: this.$store.state.dateRange.b_date,
          e_date: this.$store.state.dateRange.e_date
        }
        let res = await apiAnalysisData('profitCurve', params)
        if (res.data.code === 0 && res.data.data) {
          const resData = JSON.parse(JSON.stringify(res.data.data))
          let categories = []
          let f1s = []
          let f2s = []
          Array.isArray(resData) && resData.map(item => {
            if (item.date) {
              categories.push(item.date)
            }
            if (item.f1 || item.f1 === 0) {
              f1s.push(item.f1)
            }
            if (item.f2 || item.f2 === 0) {
              f2s.push(item.f2)
            }
          })
          this.options.xAxis.categories = categories;
          this.options.series = [
            {
              name: '累计盈亏',
              data: f1s
            }, {
              name: '累计手续费',
              data: f2s
            }];
          this.$refs.chart.redraw();
          loadingInstance.close();
        }
      }
    },
    mounted () {
      this.getData();
    }
  }
</script>

<style lang="scss">
    .profit-curve {
        .el-card {
            .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }

            .chart {
                margin-bottom: 20px;
                background-image: url("../../assets/watermark.png");
                background-repeat: no-repeat;
                background-size: 269px 150px;
                background-position: center;
            }
        }
    }
</style>
